$primary: #343a40;
$secondary: #eaeaea;
$success: #0cce9a;
$warning: #ecb364;
$white: #f0f0f0;
$white2: #eaeaea;
$white3: #e0e0e0;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
);

$body-bg: $white;

$input-border-color: $white;
$input-bg: $white2;
$input-disabled-bg: $white3;

$border-radius: 1rem;
$border-radius-lg: 1.2rem;
$border-width: 1px;

$nav-tabs-link-active-bg: $white2;
$nav-pills-link-active-color: $white2;

$modal-inner-padding: 1.5rem;
$modal-content-bg: $white;
$modal-content-border-color: $white;
$modal-backdrop-opacity: 0.2;
$modal-header-padding: 1.5rem 1.5rem;

@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./bootswatch.scss";
