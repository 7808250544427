@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap");

body::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}

body::-webkit-scrollbar-thumb {
  background-color: #343a40;
}

body {
  background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  min-width: 320px;
  position: relative;
  margin: 0 auto;
  font-family: "Crimson Text", serif;
  padding-bottom: 2rem;
  min-height: 100vh;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/* Rules for sizing the icon. */
.material-icons.md-14nb {
  font-size: 14px;
  position: relative;
}

.material-icons.md-14 {
  font-size: 14px;
  position: relative;
  bottom: -2px;
}

.material-icons.md-18 {
  font-size: 18px;
  position: relative;
  bottom: -2px;
}

.material-icons.md-24 {
  font-size: 24px;
  position: relative;
  bottom: -3px;
}

.material-icons.md-36 {
  font-size: 36px;
  position: relative;
  bottom: -2px;
}

.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.9);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

p,
span {
  white-space: pre-wrap;
}

li,
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline;
}

.widescreen {
  display: initial;
}

.thinscreen {
  display: none;
}

.verysmall {
  font-size: 0.66rem;
}

.nohover {
  pointer-events: none;
}

@media only screen and (max-width: 800px) {
  .widescreen {
    display: none;
  }

  .thinscreen {
    display: initial;
  }
}
